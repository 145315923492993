<template>
  <div :class="{ dark: isColorDark, 'is-unset':!value }" class="ext-color-picker">
    <div
      v-if="!hasInput"
      :style="{ backgroundColor: value }"
      class="ext-color-picker__chosen-color"
      @click="showPicker"
    >
      {{ value || placeholder }}
    </div>
    <div
      v-if="hasInput"
      :style="{ backgroundColor: value }"
      class="ext-color-picker__chosen-color"
      @click="showPicker"
    >
      <input
        v-model="colorInput"
        :placeholder="placeholder"
        type="text"
        class="ext-color-picker__chosen-color-picker"
      >
    </div>
    <el-color-picker ref="colorPicker" v-model="color" :class="{dark: isColorDark}" />
  </div>
</template>

<script>
import { isColorDark } from '../../utils/colorUtils'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hasInput: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    color: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    colorInput: {
      get () {
        return this.value
      },
      set (value) {
        let val = value
        const hasHash = val.indexOf('#')
        if (val && hasHash === -1) {
          val.length > 6 && (val = val.substring(0, 6))
          val = '#' + val
        }
        this.$emit('input', val)
      }
    },
    isColorDark () {
      return isColorDark(this.value)
    }
  },
  methods: {
    showPicker () {
      this.$refs.colorPicker.handleTrigger()
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.ext-color-picker {
  display: flex;

  .el-color-picker {
    &__trigger {
      border: none;
      padding: 0;
    }

    &__color {
      border: none;
    }

    &__icon {
      color: $black;
    }

    &.dark .el-color-picker__icon {
      color: $white;
    }
  }

  &__chosen-color {
    border-right: 1px solid $dark;
    text-align: center;
    flex: 1 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark;

    .dark & {
      color: $white;
      border-color: $white;

      input, input::placeholder {
        color: $white;
      }
    }

    input {
      // limit width so it doesnt overflow
      max-width: 80px;
      border: none;
      background: none;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
